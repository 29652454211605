import React from "react";
import ReactDom from "react-dom";
import { App } from "./view/app";

/**
 * 描画先の `<div>` 要素
 */
const appElement = document.createElement("div");
appElement.id = "app";
document.body.appendChild(appElement);
document.body.id = "root";

/**
 * 描画する
 */
ReactDom.render(React.createElement(App), appElement);
